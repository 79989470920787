// Dependencies
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
// Hooks
// import useSessionStorage from '../hooks/useSessionStorage'
// Components
import Link from './Link'

export function useNotifications(hasNotifications) {

	const ref = useRef()
	const [height, setHeight] = useState(0)
	const [willShow, content] = useContent()
	const [show, setShow] = useState(false)

	useEffect(() => {
		if (ref.current) {
			calculateHeight()
		}
	}, [ref])

	useEffect(() => {
		if (ref.current) {
			const dist = show ? ref.current.offsetHeight : 0
			document.documentElement.style.setProperty('--note-height', `${dist}px`);
		}
	}, [show])

	useEffect(() => {
		let timer
		timer = setTimeout(() => {
			clearTimeout(timer)
			setShow(true)
			// timer = setTimeout(() => {
			// 	clearTimeout(timer)
			// 	setShow(false)
			// }, 10000)
		}, 3000)
		return () => clearTimeout(timer)
	}, [])

	useEffect(() => {
		window.addEventListener('resize', calculateHeight)
		return () => {
			window.removeEventListener('resize', calculateHeight)
		}
	}, [])

	const calculateHeight = () => {
		if (ref.current) {
			const h = ref.current.offsetHeight
			setHeight(h)
			document.documentElement.style.setProperty('--note-height', `${h}px`)
		}
	}

	const close = () => setShow(false)

	const ready = willShow && show && hasNotifications && height

	return [ready, content, height, ref, close]
}

function getCurrentDate() {
  const d = new Date();
  let month = (d.getMonth() + 1).toString();
  if (month.length < 2) {
    month = `0${month}`;
  }
  let day = d.getDate().toString();
  if (day.length < 2) {
    day = `0${day}`;
  }
  return parseInt(`${d.getFullYear()}${month}${day}`, 10);
}

function useContent() {
	const data = useStaticQuery(graphql`
		{
			allContentfulNotification(limit: 1, sort: {fields: end, order: DESC}) {
				nodes {
					message
					end(formatString: "YYYYMMDD")
					start(formatString: "YYYYMMDD")
					internalLink
					externalLink
					linkLabel
				}
			}
		}
	`)
	const content = data.allContentfulNotification.nodes[0]
	const start = parseInt(content.start, 10)
	const end = parseInt(content.end, 10)
	const now = getCurrentDate()
	const isCurrent = (now >= start) && (now <= end)
	return [isCurrent, content]
}

const Message = ({ message, internalLink, externalLink, linkLabel }) => {

	const hasLink = internalLink || externalLink
	
	return (
		<div className="inlb wauto upcase has-links">
			<div className="align-left">
				<span>{hasLink ? `${message} — ` : message}</span>
				{hasLink && (
					<Link to={internalLink} href={externalLink}>{linkLabel || 'Find out more'}</Link>
				)}
			</div>
		</div>
	)
}

const Notifications = ({ show, content, noteRef, handleClose }) => {

	// const scale = show ? `scale(1, 1)` : `scale(1, 0)`
	const translate = !show ? `translateY(-100%)` : `translateY(0%)`
	const style = {
		transform: `${translate}`,
		transition: `transform 0.2s var(--intro-easing)`,
		transformOrigin: 'top',
	}
	
	return (
		<div className="notification pfix w100 inner guttersx2 bg-black align-center z-notify" style={style} ref={noteRef}>
			<Message {...content} />
			<button className="pabs r0 x-icon pad" onClick={handleClose}>{"×"}</button>
		</div>
	)
}

export default Notifications

