import { useEffect, useCallback } from 'react';

export default function useUpdateSizes(callback, flags = []) {

	useEffect(() => {
		// Handler to call on window resize...
    function updateSizes() {
			// console.log('updateSizes()');
			// Should contain any other updates needed on resize...
			!callback || callback();
			// Also save a CSS variable for window height...
			document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
			
			// And save the header height...
			// const headerEl = document.querySelector('#header');
			// if (headerEl) {
			// 	document.documentElement.style.setProperty('--header-height', `${headerEl.offsetHeight}px`)
			// }	
    }

		document.documentElement.style.setProperty('--start-height', `${window.innerHeight}px`);

		function handleResize() {
			const isTouch = ('ontouchstart' in window);
			if (!isTouch) {
				// console.log('!isTouch > updateSizes()');
				updateSizes()
			}
			updateSizes() // test
		}
		// Add event listener...
		window.addEventListener("resize", handleResize);
		// Call handler right away so state gets updated with initial window size...
		updateSizes();
		// Cleanup...
		return () => window.removeEventListener("resize", handleResize);
	}, [flags]);

  return;
}