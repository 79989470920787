import React, { useContext, useState, useEffect } from "react"
import fetch from "isomorphic-fetch"
import Client from "shopify-buy"
import { trackProductLead, trackAddToCart, trackInitCheckout, trackBeginCheckout } from '../helpers/customAnalyticsEvents'

const client = Client.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_STORE_URL,
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  },
  fetch
)

const defaultValues = {
  cart: [],
  isOpen: false,
  loading: false,
  onOpen: () => {},
  onClose: () => {},
  addVariantToCart: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
  client,
  checkout: null
}

const isBrowser = typeof window !== `undefined`
const localStorageKey = `sftm_checkout_id`

export const StoreContext = React.createContext(defaultValues)

export function useStoreContext() {
	const storeContext = useContext(StoreContext)
	return storeContext
}

function useCurrentProduct(currentProduct) {

	const [product, set] = useState(currentProduct)

	useEffect(() => {
		set(currentProduct)

		if (currentProduct?.storefrontId) {
			client.product.fetch(currentProduct.storefrontId).then((product) => {
				// Do something with the product
				if (product) {
					
					// Transformer
					const variants = product.variants.map((variant) => {
						return {
							availableForSale: variant.available, 
							compareAtPrice: variant.compareAtPrice, 
							price: variant.price, 
							selectedOptions: variant.selectedOptions, 
							storefrontId: variant.id,
							sku: variant.sku
						}
					})

					set({
						...currentProduct,
						variants,
					})
				}
			});
		}
	}, [currentProduct])

	return [product, set]
}

const StoreProvider = ({ product, children }) => {

  const [checkout, setCheckout] = useState(defaultValues.checkout)
  const [loading, setLoading] = useState(false)
  const [didJustAddToCart, setDidJustAddToCart] = useState(false)

	// Current product store
	const [currentProduct, setCurrentProduct] = useCurrentProduct(product)

  const setCheckoutItem = (checkout) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id)
    }

    setCheckout(checkout)
  }

  useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null

      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const existingCheckout = await client.checkout.fetch(
            existingCheckoutID
          )
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout)
            return
          }
        } catch (e) {
          localStorage.setItem(localStorageKey, null)
        }
      }

      const newCheckout = await client.checkout.create()
      setCheckoutItem(newCheckout)
    }

    initializeCheckout()
  }, [])

  const addVariantToCart = (variantId, quantity, customAttributes, callback) => {
    setLoading(true)

    const checkoutID = checkout.id

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
				customAttributes
      },
    ]

    return client.checkout
      .addLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
				console.log('finished adding to cart...')
        setCheckout(res)

				if (callback) {
					callback(res)
				}

        setLoading(false)
        setDidJustAddToCart(true)
        setTimeout(() => setDidJustAddToCart(false), 500)
      })
  }

  const removeLineItem = (checkoutID, lineItems) => {
    setLoading(true)

    return client.checkout
      .removeLineItems(checkoutID, lineItems)
      .then((res) => {
        setCheckout(res)
        setLoading(false)
      })
  }

  const updateLineItem = (checkoutID, lineItemID, quantity) => {
    setLoading(true)

    const lineItemsToUpdate = [
      { id: lineItemID, quantity: parseInt(quantity, 10) },
    ]

    return client.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res)
        setLoading(false)
      })
  }

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        checkout,
        loading,
        didJustAddToCart,
				currentProduct,
				setCurrentProduct
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}

export default StoreProvider