// Hooks & Helpers
import priceLabel from '../helpers/priceLabel'

function useVariantInfo(variant) {
	if (!variant) return {}

	const price = variant?.price?.amount ? priceLabel(variant.price.amount) : priceLabel(variant.price)
	const compareAtPrice = 
    variant.compareAtPrice?.amount 
    ? priceLabel(variant.compareAtPrice.amount, true)
    : variant.compareAtPrice 
    ? priceLabel(variant.compareAtPrice, true)
    : null

	return { 
    price, 
    compareAtPrice,
    variantPrice: (variant.price?.amount || variant.price), 
  }
}

export default useVariantInfo