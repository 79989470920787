// Dependencies
import React from "react"
import { Link } from "gatsby"

const CustomLink = (props) => (
	props.href ? (
		<a href={props.href} className={props.className} style={props.style} target="_blank" rel="noopener noreferrer">{props.children}</a>
	) : props.to ? (
		<Link {...props} />
	) : <span className={props.className}>{props.children}</span>
)

export default CustomLink // as Link
