// Dependencies
import React from "react"
// Hooks
import useUpdateSizes	from "../hooks/useUpdateSizes"
// Components
import Menu, { MenuHeader } from "../components/Menu"
import Notifications, { useNotifications } from "../components/Notifications"
// Wrappers
import Store from "../wrappers/Store"
import Navigation, { useNavigationContext } from "../wrappers/Navigation"

const Wrapper = ({ children }) => {

	const { menuState: { isMenuFocused } } = useNavigationContext()

	const speed = 0.5
	const transition = `opacity ${speed}s, filter ${speed}s`
	const style = isMenuFocused ? {
		opacity: '0.5',
    filter: `blur(25px)`,
		pointerEvents: 'none',
		transition: transition
	} : {
		opacity: '1',
		transition: transition
	}

	return (
		<div style={style}>
			{children}
		</div>
	)
}

const App = ({ pageContext, children }) => {

	useUpdateSizes()

	const [
		showNotification, 
		notificationContent, 
		noteHeight, 
		ref, 
		handleClose
	] = useNotifications(!pageContext.forceHideNotifications)

	const wrapperStyle = {
		marginTop: showNotification ? noteHeight : '0',
		transition: `all 0.3s var(--intro-easing) 0.3s, margin 0.2s var(--intro-easing)`,
	}

	// console.log(noteHeight)

	return (
		<Store product={pageContext.currentProduct}>
			<Navigation pageContext={pageContext}>
				<Notifications 
					show={showNotification} 
					content={notificationContent} 
					noteRef={ref} 
					handleClose={handleClose}
				/>
				<div id="___sftm" style={wrapperStyle}>
					{!pageContext.forceHideMenu && <MenuHeader style={wrapperStyle} />}
					{!pageContext.forceHideMenu && <Menu />}
					<Wrapper>{children}</Wrapper>
				</div>
			</Navigation>
		</Store>
	)
}

export default App
