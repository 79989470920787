// Dependencies
import React, { useRef, useEffect, useState } from 'react'
import { graphql, useStaticQuery, Link, navigate } from 'gatsby'
// import { useSpring, animated } from 'react-spring'
// import { useLocation } from "@reach/router"
// Hooks & Helpers
// import priceLabel from '../helpers/priceLabel'
// import useOnClickOutside from '../hooks/useOnClickOutside'
// import useLineItem from '../hooks/useLineItem'
// import useVariantInfo from '../hooks/useVariantInfo'
// Components
// import Link from '../components/Link'
// import Markdown from '../components/Markdown'
import MenuProductInfo from '../components/MenuProductInfo'
import { MenuCartLink } from '../components/MenuCart'
// Wrappers
import { useNavigationContext } from '../wrappers/Navigation'
// import { useStoreContext } from '../wrappers/Store'

export function useMenuPositioning(focused) {

	const [{x, y}, setPosition] = useState({ x: 0, y: 0 })
	const reposition = (coords) => setPosition(coords)

	const menuTransform = !focused ? `translateX(${x + 12}px) translateY(0%)` : `translateX(12px) translateY(0%)`

	return { reposition, menuTransform }
}

export const MenuHotspot = () => {

	const { menuState } = useNavigationContext()
	const { handleMenuFocus, isMenuFocused } = menuState

	const focus = () => {
		handleMenuFocus(true)
	}

	return (
		!isMenuFocused && <div className="menu__hotspot" onClick={focus} />
	)
}

export const MenuHeader = ({ style }) => {

	const { menuState } = useNavigationContext()
	const { toggleMenuFocus, isMenuFocused, handleMenuFocus, setActivePath } = menuState

	const handleClick = () => {
		if (isMenuFocused) {
			handleMenuFocus(false)
			setActivePath(null)
		} else {
			handleMenuFocus(true)
		}
	}

	return (
		<header className={`menu__header ${isMenuFocused ? 'open' : ''}`} style={style}>
			<button className="btn" onClick={handleClick}>{isMenuFocused ? 'Close' : 'Menu'}</button>
		</header>
	)
}

const MenuBranch = ({ branch, sharedProps }) => {

	// Props
	const { menuState, currentMenuPath, currentProduct, setMenuPath, menuRef, reposition } = sharedProps
	const { activePath, setActivePath, handleMenuFocus } = menuState

	// Refs
	const positionRef = useRef()
	
	// Content
	const { label, match, path, href, links, willShowProduct, willShowCart } = branch

	// UX
	const hasActivePath = activePath?.includes(match)
	const hasCurrentPage = currentMenuPath?.includes(match)
	const isCurrentPage = currentMenuPath === match
	const willShowBranch = activePath ? hasActivePath : hasCurrentPage
	const willShowProductBranch = currentProduct && isCurrentPage && willShowProduct
	// console.log(currentMenuPath);
	
	useEffect(() => {
		if (isCurrentPage) {
			const activePosition = positionRef.current.getBoundingClientRect().left
			const menuPosition = menuRef.current.getBoundingClientRect().left
			const adjust = (!currentMenuPath) || (currentMenuPath === '/') ? -12 : 12

			if (!currentMenuPath || currentMenuPath === '/') {
				reposition({ x: 0, y: 0 })
			} else {
				reposition({
					x: menuPosition - activePosition + adjust, // + 12
					y: 0
				})
			}
		}
	}, [isCurrentPage])

	// UI
	const handleLinkClick = (event) => {
		event.preventDefault()
		if (path) {
			navigate(path)
			// console.log('handleLinkClick + path?')
			
			if (isCurrentPage) {
				handleMenuFocus(false)
			}

			if (path === '/') {
				setMenuPath('/')
				setActivePath(null)
			} else {
				setActivePath(match)
			}
		} else {
			setActivePath(match)
		}
	}

	return (
		<div className={`menu__branch prel ${links ? 'prel' : ''} ${isCurrentPage ? 'test' : ''}`} ref={positionRef}>
			<MenuHotspot />
			
			{href ? (
				<a href={href} className={`menu__link`} target="_blank" rel="noopener noreferrer">
					<div className="label">{label}</div>
				</a>
			) : willShowCart ? (
				<MenuCartLink handleMenuFocus={handleMenuFocus} />
			) : (
				path ? (
					<Link to={path} className={`menu__link ${willShowBranch ? 'active' : ''}`} onClick={handleLinkClick}>
						<div className="label">{label}</div>
						{(links || currentProduct && isCurrentPage) && <div className={`line ${willShowProductBranch ? 'is-last' : ''}`} />}
					</Link>
				) : (
					<div className={`menu__link ${willShowBranch ? 'active' : ''}`} onClick={handleLinkClick}>
						<div className="label">{label}</div>
						{(links || currentProduct && isCurrentPage) && <div className={`line ${willShowProductBranch ? 'is-last' : ''}`} />}
					</div>
				)
			)}

			{links ? (
				<div className={`menu__branches branch-wrapper ${willShowBranch ? 'active' : ''}`}>
					{links.map((linkBranch, index) => (
						<MenuBranch branch={linkBranch} sharedProps={sharedProps} key={`${linkBranch.match}${index}`} />
					))}
				</div>
			) : willShowProductBranch ? (
				<MenuProductInfo product={currentProduct} positionRef={positionRef} />
			) : null}
		</div>
	)
}

const Menu = () => {

	// Context
	const navigationContext = useNavigationContext()
	const { menuState } = navigationContext

	// Refs
	const menuRef = useRef()
	const focusTimer = useRef()

	// Content
	const content = useMenuContent()

	// UX
	const { isMenuFocused, handleMenuFocus, setActivePath } = menuState
	const { reposition, menuTransform } = useMenuPositioning(isMenuFocused)

	// UI
	const handleMouseMove = (event) => {
		if (window.matchMedia("(hover: hover)").matches) {
			clearTimeout(focusTimer.current)
			if (event.clientX < 24) {
				handleMenuFocus(true)
			}
		}
	}

	const handleMouseLeave = () => {
		if (window.matchMedia("(hover: hover)").matches) {
			handleMenuFocus(false)
			setActivePath(null)
		}
	}

	// Pass props
	const sharedProps = {
		...navigationContext,
		menuRef,
		reposition
	}

	// Style 
	const style = {
		transform: menuTransform,
		transition: '0.75s'
	}
	
	return (
		<nav 
			className={`menu ${isMenuFocused ? 'focused' : ''}`} 
			onMouseMove={handleMouseMove}
			onMouseLeave={handleMouseLeave}
			ref={menuRef}
			style={style}
		>
			<div className="menu__branches branch-wrapper active">
				{content.map((branch, index) => (
					<MenuBranch branch={branch} sharedProps={sharedProps} key={`${branch.match}${index}`} />
				))}
			</div>
		</nav>
	)
}

function useMenuContent() {
	const data = useStaticQuery(graphql`
		{
			allContentfulSettings(filter: {contentful_id: {eq: "1NvlpsawwN2IEieW8uTZvy"}}) {
				nodes {
					menuChapters {
						title
						slug
						chapterNumber
						galleries {
							title
							slug
						}
					}
					menuShop {
						... on Node {
							... on ContentfulProductDepartment {
								__typename
								title
								slug
								category {
									slug
								}
								allLabel
								productTypes {
									title
									slug
								}
							}
							... on ContentfulMenuLink {
								__typename
								label
								path: internalLink
							}
						}
					}
					menuAbout {
						title
						slug
					}
					# menuProjects {
					# 	title
					# 	slug
					# }
					menuContact {
						label
						href
						path: internalLink
						description {
							childMarkdownRemark {
								html
							}
						}
					}
					menuStockists {
						title
						slug
					}
					menuLegal {
						title
						slug
					}
				}
			}
		}
	`)
	const { menuChapters, menuShop, menuContact, menuStockists, menuLegal, menuAbout } = data.allContentfulSettings.nodes[0]

	return [
		...(menuShop?.map((entry) => {
			if (entry.__typename === 'ContentfulProductDepartment') {
				const path = `/${entry.category.slug}/${entry.slug}`; // eg. "/shop/mens"
				return {
					label: entry.title,
					path: path,
					match: path,
					links: [
						{
							label: entry.allLabel || 'All',
							path: `${path}#thumbnails`,
							match: `${path}/all`,
						},
						...(entry.productTypes?.map((type) => {
							return {
								label: type.title,
								path: `${path}/${type.slug}`,
								match: `${path}/${type.slug}`,
								willShowProduct: true
							}
						}) || []),
					]
				}
			} else if (entry.__typename === 'ContentfulMenuLink') {
				return {
					label: entry.label,
					path: entry.path,
					// match: entry.path,
				}
			}
		})),
		{
			label: 'Chapters',
			// path: `/`,
			match: `/chapters`,
			links: [
				...(menuChapters?.map((chapter) => {
					return {
						label: `${chapter.chapterNumber} ${chapter.title}`,
						path: `/${chapter.slug}`,
						match: `/chapters/${chapter.slug}`,
						links: chapter.galleries?.map((gallery) => {
							return {
								label: gallery.title,
								path: `/${chapter.slug}/${gallery.slug}`,
								match: `/chapters/${chapter.slug}/${gallery.slug}`
							}
						})
					}
				})),
				{
					label: 'All chapters',
					path: '/',
					match: '/'
				}
			]
		},
		// ...(menuProjects ? [{
		// 	label: 'Projects',
		// 	// path: '/projects',
		// 	match: '/projects',
		// 	links: menuProjects.map((project) => {
		// 		return {
		// 			label: project.title,
		// 			path: `/projects/${project.slug}`,
		// 			match: `/projects/${project.slug}`
		// 		}
		// 	})
		// }] : []),
		{
			label: 'Info',
			match: `/info`,
			links: [
				...(menuAbout?.map((link) => {
					return {
						label: link.title,
						path: `/info/${link.slug}`,
						match: `/info/${link.slug}`
					}
				}) || []),
				{
					label: 'Contact',
					path: `/info/contact`,
					match: `/info/contact`,
					links: menuContact
				},
				{
					label: 'Stockists',
					match: '/info/stockists',
					links: menuStockists.map((category) => {
						return {
							label: category.title,
							path: `/info/stockists/${category.slug}`,
							match: `/info/stockists/${category.slug}`
						}
					})
				},
				{
					label: 'FAQs',
					match: '/info/faqs',
					links: menuLegal?.map((link) => {
						return {
							label: link.title,
							path: `/info/faqs/${link.slug}`,
							match: `/info/faqs/${link.slug}`,
						}
					}),
				}
			]
		},
		{
			label: 'Cart',
			match: `/cart`,
			willShowCart: true
		}
	];
}

export default Menu