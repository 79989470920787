import React, { useContext, useState, useEffect } from "react"
import { useLocation } from "@reach/router"
import useLocalStorage from "../hooks/useLocalStorage"

export const NavigationContext = React.createContext()

export function useNavigationContext() {
	const navContext = useContext(NavigationContext)
	return navContext
}

function useMenuState() {

	const location = useLocation()
	const [activePath, setActivePath] = useState(null)
	const [isMenuFocused, setMenuFocused] = useState(false)
	
	const handleMenuFocus = (bool) => {
		setMenuFocused(bool)
	}

	const toggleMenuFocus = () => {
		setMenuFocused(o => !o)
	}

	useEffect(() => {
		setActivePath(null)
		setMenuFocused(false)
	}, [location.pathname])

	return {
		isMenuFocused,
		handleMenuFocus,
		toggleMenuFocus,
		activePath,
		setActivePath,
	}
}

function useMenuPath(currentMenuPath) {
	const location = useLocation()
	const [current, set] = useState(currentMenuPath)

	useEffect(() => {
		if (current !== currentMenuPath) {
			set(currentMenuPath)
		}
		// eslint-disable-next-line
	}, [currentMenuPath, location.pathname])

	return [current, set]
}

const Navigation = ({ pageContext, children }) => {

	const { currentProduct } = pageContext

	const [currentMenuPath, setMenuPath] = useMenuPath(pageContext.menuPath)
	const menuState = useMenuState()
	const cookiesState = useLocalStorage('sftm_cookie_accept', false)
	const newsletterState = useLocalStorage('sftm_show_popup', false)

	return (
		<NavigationContext.Provider value={{ 
			currentMenuPath, 
			setMenuPath, 
			currentProduct, 
			menuState,
			cookiesState,
			newsletterState
		}}>
			{children}
		</NavigationContext.Provider>
	)
}

export default Navigation