import * as React from "react"
// import { StoreProvider } from "./src/context/store-context"
import App from "./src/wrappers/App"
import { trackPageView } from './src/helpers/customAnalyticsEvents'

export const wrapPageElement = ({ props: { pageContext }, element }) => (
	<App pageContext={pageContext}>{element}</App>
)

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }
}

export const onRouteUpdate = () => {
	// { location, prevLocation }
	trackPageView()
}

// https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#shouldUpdateScroll
// export const shouldUpdateScroll = ({
//   routerProps: { location },
//   getSavedScrollPosition
// }) => {
//   const currentPosition = getSavedScrollPosition(location)
//   // const queriedPosition = getSavedScrollPosition({ pathname: `/random` })

// 	if (location.pathname === '/') {
// 		window.scrollTo(...([0, 0]))
// 	} else {
// 		window.scrollTo(...(currentPosition || [0, 0]))
// 	}

//   return false
// }
