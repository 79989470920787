// Dependencies
import React, { useEffect, useState, useCallback } from 'react'
// Hooks & Helpers
import useVariantInfo from '../hooks/useVariantInfo'
import { trackProductLead, trackAddToCart } from '../helpers/customAnalyticsEvents'
// Components
import Link from '../components/Link'
import Markdown from '../components/Markdown'
// Wrappers
import { useStoreContext } from '../wrappers/Store'

const BuyButton = (props) => {

	// console.log(props)

	// Context
	const { addVariantToCart, didJustAddToCart, loading } = useStoreContext()

	// Content
	const { selected, product } = props
	const { title, handle, colourMaterial } = product

	// UX
	const [used, setUsed] = useState(false)
	const [inCart, setInCart] = useState(false)

	useEffect(() => {
		if (didJustAddToCart && !inCart) {
			setInCart(true)
		}
	}, [didJustAddToCart])

	// UI
	const handleClick = () => {
		setUsed(true)
		
		if (selected) {
			const customAttributes = [
				{ key: "title", value: title },
				{ key: "handle", value: handle },
				{ key: "colourMaterial", value: colourMaterial }
			]

			addVariantToCart(selected.storefrontId, 1, customAttributes, (res) => {
				trackAddToCart({
					...props.trackItem,
					sku: selected.sku,
				})
			})
		}
	}

	return (
		loading ? (
			<button className="btn inactive">Adding...</button>
		) : inCart ? (
			<Link to="/cart" className="btn">Review cart</Link>
		) : used && !selected ? (
			<button className="btn--bold inactive" onClick={handleClick}>^ Pick a size</button>
		) : (
			<button className={`btn--bold--invert ${props.selected ? 'active' : ''}`} onClick={handleClick}>Add To Cart</button>
		)
	)
}

const ProductSizes = ({ className, variants, handleVariantSelect, selected }) => {

	return (
		(variants.length > 1) && (
			<div className={`product__sizes ${className}`}>
				{variants.map((variant, index) => {
					
					const key = `${variant.storefrontId}-size-${index}`
					const isActive = variant.storefrontId === selected?.storefrontId
					const className = `btn--square ${!variant.availableForSale ? 'inactive' : ''} ${isActive ? 'active' : ''}`
					// const size = variant.selectedOptions[0]?.value?.substring(0, 3)
					const size = variant.selectedOptions[0]?.value

					const handleClick = () => {
						if (variant.availableForSale) {
							handleVariantSelect(index)
						}
					}

					return (
						<button className={className} onClick={handleClick} key={key}>{size}</button>
					)
				})}
			</div>
		)
	)
}

function useProductInfo({ variants }) {

	const [selected, set] = useState(variants?.length === 1 ? variants[0] : null)
	const currentSelection = selected || variants[0]
	const isAvailable = variants.find(variant => variant.availableForSale) ? true : false
	const { price, compareAtPrice, variantPrice } = useVariantInfo(currentSelection)

	const handleVariantSelect = (index) => {
		set(variants[index])
	}

	return { selected, price, compareAtPrice, handleVariantSelect, variants, isAvailable, variantPrice }
}

function useProductInfoPositioning(ref) {
	
	const [y, set] = useState(0)

	useEffect(() => {
		let timer;
		const reposition = () => {
			if (ref.current) {
				// Align 100% height container to top of window
				const menuItemY = ref.current.getBoundingClientRect().top
				const menuItemHeight = ref.current.offsetHeight
				set(-menuItemY - menuItemHeight/2)
			}
		}
		const onResize = () => {
			clearTimeout(timer)
			timer = setTimeout(reposition, 500)
		}
		reposition()
		window.addEventListener('resize', onResize)
		return () => window.removeEventListener('resize', onResize, false)
	}, [])

	const transform = `translateY(${y}px) translateX(100%)`

	return [transform]
}

export const ProductInfo = (props) => {

	// Content
	const { 
		title, 
		handle, 
		comingSoon,
		comingSoonLabel,
		colourMaterial, 
		summary, 
		productDetails, 
		sizeChartRef, 
		measurements, 
		modelSize, 
		productId, 
		images 
	} = props
	const productInfo = useProductInfo(props)
	const { price, compareAtPrice, isAvailable, variantPrice } = productInfo

	const trackItem = {
		value: price,
		price: variantPrice || price,
		compareAtPrice,
		sku: null,
		title,
		department: props.department[0].slug,
		category: props.type[0].slug,
		productId: productId ? productId.split('/Product/')[1] : null,
		imageSrc: images[0].src,
		brand: 'Song for the Mute',
		currency: 'AUD'
	}

	const track = useCallback(() => {
		trackProductLead(trackItem)
	}, [handle])
	useEffect(track, [handle])

	return (
		<div className="product__info">
			<div>
				<p className="product__title">
					{title} <br /> {colourMaterial} <br />
					{!comingSoon && compareAtPrice && <span className="strikethru inlb mr">{compareAtPrice}</span>}
					{!comingSoon && price}
				</p>
				{summary && (
					<Markdown className="p2 bookish outerx4" field={summary} />
				)}
				
				{productDetails && (
					<details className="p3 outerx2">
						<summary className="h3 upcase">Product Details</summary>
						<Markdown className="ul1 p3 mtx2" field={productDetails} />
					</details>
				)}

				{sizeChartRef && (
					<details className="p3 outerx2">
						<summary className="h3 upcase">Size chart</summary>
						{modelSize && <p className="p3 mtx2">{modelSize}</p>}
						<Markdown className="p3 size-chart mtx2" field={sizeChartRef.table} />
					</details>
				)}
				
				{/* Testing... */}
				{/* <div className="mbx4">
					{measurements && (
						<details className="p3 outerx2 prel">
							<summary className="h3 upcase mbx2">Measurements</summary>
							<div className="size-chart-scroll">
								<Markdown className="p3 size-chart" field={measurements} />
							</div>
							
						</details>
					)}
				</div> */}
			</div>

			{!comingSoon ? (
				<div>
					{isAvailable && <ProductSizes className="mbx2" {...productInfo} />}
					{isAvailable ? <BuyButton product={props} {...productInfo} trackItem={trackItem} /> : <span>— SOLD OUT —</span>}
				</div>
			) : (
				<div>
						<span className="h3 upcase">{comingSoonLabel || 'Coming soon'}</span>
				</div>
			)}
			
		</div>
	)
}

export function useProductInfoWhenReady(currentProduct) {
	const [ready, setReady] = useState(false)
	const handle = currentProduct?.handle

	useEffect(() => {
		const timer = setTimeout(() => {
			setReady(true)
		}, 2000)
		if (currentProduct) {
			setReady(true)
			clearTimeout(timer)
		}
		return () => {
			clearTimeout(timer)
		}
	}, [handle])

	return ready
}

const MenuProductInfo = (props) => {

	// Props
	const { positionRef } = props
	const { currentProduct } = useStoreContext()

	// UX
	const ready = useProductInfoWhenReady(currentProduct)
	const [transform] = useProductInfoPositioning(positionRef)
	
	// Style
	const style = {
		transform
	}

	return (
		<div className="menu__product__branch hide-scrollbars" style={style}>
			<div className="menu__product__wrapper">
				<div className="menu__product branch-wrapper">
					{ready && <ProductInfo {...currentProduct} />}
				</div>
			</div>
		</div>
	)
}

export default MenuProductInfo
