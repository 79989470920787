const isBrowser = typeof window !== "undefined"
const isProduction = process.env.NODE_ENV === 'production'
const willTrack = isBrowser && isProduction
const _fbq = isBrowser ? (window.fbq || window._fbq || []) : []
const _gtag = isBrowser ? (window.gtag || window._gtag || []) : []
const _learnq = isBrowser ? (window.learnq || window._learnq || []) : []
// GOOGLE DOCS
// https://developers.google.com/analytics/devguides/collection/gtagjs/events

// FACEBOOK DOCS
// https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
// https://developers.facebook.com/docs/facebook-pixel/reference#standard-events

// KLAVIYO DOCS
// ...

const currency = 'AUD'
const brand = 'Song for the Mute'

// export function acceptsCookies() {
// 	if (isBrowser) {
// 		_fbq('consent', 'grant')
// 	}
// }

// Page view
export function trackPageView() {
	if (willTrack) {
		console.log('page_view fired...');
		_gtag("event", "page_view", {
			event_callback: function() {
				console.log('trackPageView success!')
			}
		})
	}
}

// Product Lead
export function trackProductLead({ 
	value,
	price,
	compareAtPrice,
	sku,
	title,
	department,
	category, 
	productId,
	imageSrc,
}) {
	if (willTrack) {
		console.log('_gtag?', _gtag)
		console.log('_fbq?', _fbq)
		console.log('_learnq?', _learnq)
		console.log('trackProductLead')
		// Facebook
		_fbq('track', 'Lead', { 
			content_name: title,
			content_ids: [sku],
			currency, 
			value 
		})
		// Google
		_gtag("event", "view_item", {
			currency,
			value,
			items: [
				{
					item_id: sku,
					item_name: title,
					currency: currency,
					item_brand: brand,
					item_category: "Apparel",
					item_category2: department,
					item_category3: category,
					price: value,
					quantity: 1
				}
			],
			event_callback: function() {
				console.log('trackProductLead success!')
			}
		});
		// Klaviyo
		_learnq.push(['track', 'Viewed Product', {
			Name: title,
			ProductID: productId,
			ImageURL: imageSrc,
			URL: window.location.href,
			Brand: brand,
			Price: price,
			CompareAtPrice: compareAtPrice
		}]);
		_learnq.push(['trackViewedItem', {
			Title: title,
			ItemId: productId,
			ImageUrl: imageSrc,
			Url: window.location.href,
			Metadata: {
				Brand: 'Song for the Mute',
				Price: price,
				CompareAtPrice: compareAtPrice
			}
		}]);
	}
}

// Add to cart
export function trackAddToCart(data) {

	// Tracking data
	const { 
		value,
		price,
		sku,
		title,
		department,
		category, 
		compareAtPrice,
		productId,
		imageSrc,
		currency,
		brand
	} = data

	// const trackItem = {
	// 	value: price,
	// 	price,
	// 	compareAtPrice,
	// 	sku: null,
	// 	title,
	// 	department: props.product___department[0].slug,
	// 	category: props.type[0].slug,
	// 	productId: productId ? productId.split('/Product/')[1] : null,
	// 	imageSrc: images[0].src
	// }

	// fbq('track', 'AddToCart', {
	// 	content_name: 'Really Fast Running Shoes', 
	// 	content_category: 'Apparel & Accessories > Shoes',
	// 	content_ids: ['1234'],
	// 	content_type: 'product',
	// 	value: 4.99,
	// 	currency: 'USD' 
	// }); 

	
	if (willTrack) {
		
		const fb_data = { 
			content_name: title,
			content_ids: [sku],
			currency, 
			value: price
		}
		console.log('trackAddToCart', fb_data)
		// Facebook
		_fbq('track', 'AddToCart', fb_data)
		// Google
		_gtag("event", "add_to_cart", {
			currency,
			value,
			items: [
				{
					item_id: sku,
					item_name: title,
					currency: currency,
					item_brand: brand,
					item_category: "Apparel",
					item_category2: department,
					item_category3: category,
					price: value,
					quantity: 1
				}
			],
			event_callback: function() {
				console.log('trackAddToCart success!')
			}
		});
		
		// Klaviyo WIP
		const klaviyoItem = {
			Name: title,
			ProductID: productId,
			Categories: [category, department],
			ImageURL: imageSrc,
			URL: window.location.href,
			Brand: brand,
			Price: value,
			CompareAtPrice: compareAtPrice,
			Quantity: 1
		}
		
		_learnq.push(['track', 'Added to Cart', klaviyoItem]);
	}
}

// Initialise checkout
export function trackInitCheckout({
	checkoutId,
	lineItems,
	subtotal
}) {
	if (willTrack) {
		console.log('trackInitCheckout', {
			checkoutId,
			lineItems,
			subtotal
		})
		// Facebook
		_fbq('track', 'InitiateCheckout', { 
			content_ids: [checkoutId],
			currency, 
			num_items: lineItems.length
		})
		// Google
		_gtag("event", "view_cart", {
			currency: currency,
			value: subtotal,
			items: lineItems.map((item) => {
				return {
					item_id: item.id,
					item_name: item.title,
					currency: currency,
					item_brand: brand,
					item_category: "Apparel",
					price: item.price,
					quantity: item.quantity
				}
			}),
			event_callback: function() {
				console.log('trackInitCheckout success!')
			}
		});
		// Klaviyo
		// ...
	}
}

// Begin checkout
export function trackBeginCheckout({
	checkoutId,
	lineItems,
	subtotal
}) {
	if (willTrack) {
		console.log('trackBeginCheckout')
		// Facebook
		// ...
		// Google
		_gtag("event", "begin_checkout", {
			currency: currency,
			value: subtotal,
			items: lineItems.map((item) => {
				return {
					item_id: item.id,
					item_name: item.title,
					currency: currency,
					item_brand: brand,
					item_category: "Apparel",
					price: item.price,
					quantity: item.quantity
				}
			}),
		});
		// Klaviyo
		// ...
	}
}

export default {}