exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-cart-js": () => import("./../../../src/templates/cart.js" /* webpackChunkName: "component---src-templates-cart-js" */),
  "component---src-templates-chapter-gallery-js": () => import("./../../../src/templates/chapter-gallery.js" /* webpackChunkName: "component---src-templates-chapter-gallery-js" */),
  "component---src-templates-chapter-js": () => import("./../../../src/templates/chapter.js" /* webpackChunkName: "component---src-templates-chapter-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-newsletter-js": () => import("./../../../src/templates/newsletter.js" /* webpackChunkName: "component---src-templates-newsletter-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-shop-index-js": () => import("./../../../src/templates/shop-index.js" /* webpackChunkName: "component---src-templates-shop-index-js" */),
  "component---src-templates-stockists-js": () => import("./../../../src/templates/stockists.js" /* webpackChunkName: "component---src-templates-stockists-js" */)
}

